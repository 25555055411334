import React, { FunctionComponent } from "react"
import { PageProps } from "@src-types/page"
import { useIndexPageData } from "@hooks/use-index-page-data"
import { DevotionalsPage } from "@features/devotionals/components/devotionals-page/devotionals-page"
import { Page } from "@components/page/page"
import { PageBreadcrumbs } from "@core/breadcrumbs"
import { devotionalIndexConfig } from "@features/devotionals/devotional-index-config"
import { PageOpenGraphImage } from "@core/constants"

export const Devotionals: FunctionComponent<PageProps> = (props) => {
  const { header, featuredResources, metadata } =
    useIndexPageData("devotionals")

  return (
    <Page
      {...props}
      metadata={{
        ...metadata,
        openGraphImage: PageOpenGraphImage.Devotionals
      }}
      pageOptions={{
        headerVariant: "cream",
        breadcrumbs: PageBreadcrumbs.Devotionals,
        defaultSearchIndex: devotionalIndexConfig.defaultSearchIndex
      }}
    >
      <DevotionalsPage pageHeader={header} featuredList={featuredResources} />
    </Page>
  )
}

export default Devotionals
