import React, { FunctionComponent, Fragment } from "react"
import { IndexPageHeader } from "../../../../types/page"
import { IndexHeader } from "../../../../components/index-header/index-header"
import { SearchResults } from "../../../search/components/search-results/search-results"
import { Resource } from "../../../../types/resource"
import { FeaturedResources } from "../../../../components/featured-resources/featured-resources"
import { devotionalIndexConfig } from "../../devotional-index-config"
import { getFilteredIndexes } from "../../../search/search-helpers"

export interface DevotionalsPageProps {
  featuredList: Resource[]
  pageHeader: IndexPageHeader
  disableFilters?: boolean
}

const { filters, searchConfig, indexes } = devotionalIndexConfig

export const DevotionalsPage: FunctionComponent<DevotionalsPageProps> = ({
  featuredList,
  pageHeader,
  disableFilters
}) => {
  return (
    <Fragment>
      <IndexHeader header={pageHeader} />
      <FeaturedResources
        shouldUpdateList
        pageKey="devotionals"
        labelId="featured-devotionals"
        list={featuredList}
        type="devotional"
      />
      <SearchResults
        filters={disableFilters ? [] : filters}
        config={searchConfig}
        variant="list"
        indexes={indexes ? getFilteredIndexes(indexes) : undefined}
      />
    </Fragment>
  )
}
